import { FC } from "react";
import { Search } from "../assets/icons/svgs";

const SearchBar: FC<any> = ({ value, onchange, placeholder }) => {
  return (
    <div className="flex items-center max-w-3xl mx-auto w-full">
      <div className="relative w-full">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <svg
            className="w-4 h-4 text-gray-500 dark:text-gray-400"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 18 20"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2"
            />
          </svg>
        </div>
        <input
          type="text"
          value={value}
          id="simple-search"
          className="bg-gray-50 border py-4 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-400 focus:border-blue-400 block w-full ps-10 p-2.5   dark:focus:ring-blue-400 dark:focus:border-blue-400"
          placeholder={placeholder}
          onChange={onchange}
        />
      </div>
      <button
        type="submit"
        className="p-3.5 ms-2 text-sm font-medium text-white bg-blue-400 rounded-lg  border-blue-400 hover:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-400  dark:hover:bg-blue-400 dark:focus:ring-blue-400"
      >
      <Search/>
        <span className="sr-only">Search</span>
      </button>
    </div>
  );
};

export default SearchBar;
