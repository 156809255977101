import * as Yup from "yup";
export const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email").required("Email is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  country: Yup.string().required("Country is required"),
  phone: Yup.string()
    .matches(/^[0-9]+$/, "Phone must be a number")
    .min(9, "Phone must be at least 10 digits")
    .required("Phone is required"),
  mobile: Yup.string(),

  // streetAddress: Yup.string().required("Street address is required"),
  // streetAddress2: Yup.string().required("Street address is required"),
  city: Yup.string().required("City is required"),
  region: Yup.string().required("State/Province is required"),
  postalCode: Yup.string()
    .matches(/^[0-9]+$/, "Postal code must be a number")
    .required("Postal code is required"),
  idImage: Yup.mixed()
    .required(
      "A valid ID is a requirement for our second-hand dealers’ license. We are not able to accept a trade-in if this is not provided"
    )
    .test(
      "fileSize",
      "Image must be less than 5MB",
      (value: any) => value && value?.size <= 5 * 1024 * 1024
    )
    .test(
      "fileType",
      "Only image files are allowed",
      (value: any) =>
        value && ["image/jpeg", "image/png", "image/jpg"].includes(value.type)
    ),
});

export const emailValidationSchema = Yup.object({
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email format. Example: user@example.com"
    )
    .required("Email is required"),
});
