import React, { FC } from "react";
import { MEDIA_URL } from "../constant/constant";

export const Card: FC<any> = ({ item }) => {
  return (
    <div className="bg-gradient-to-r from-blue-400  to-green-400 rounded-2xl shadow-sm shadow-gray-300 cursor-pointer">
      <div className="group overflow-hidden relative after:duration-500 before:duration-500  duration-500 hover:after:duration-500 hover:after:translate-x-24 hover:before:translate-y-12 hover:before:-translate-x-32 hover:duration-500 after:absolute  after:bg-blue-400 after:rounded-full  after:blur-xl after:bottom-32 after:right-16 after:w-12 after:h-12  before:absolute before:w-20 before:h-20 before:bg-blue-400 before:rounded-full  before:blur-xl before:top-20 before:right-16   hover:rotate-3 flex justify-center items-center  origin-bottom-right bg-white rounded-2xl outline outline-slate-200 -outline-offset-8">
        <div className="z-10 flex flex-col items-center gap-2 p-[7px]  w-full">
          <div className="w-full h-[110px] sm:h-[125px] rounded-lg md:h-[155px] lg:h-[180px] flex items-center justify-center cursor-pointer">
            {item.image ? (
              <img
                className="rounded-t-lg object-cover h-[110px] w-full  sm:h-[125px] md:h-[155px] lg:h-[180px] "
                src={`${MEDIA_URL}${item.image}`}
                alt="item.image"
              />
            ) : (
              <img
                className="rounded-t-lg h-[60px] sm:h-[100px] md:h-[120px] lg:h-[150px] my-5 object-contain"
                src={"/placeholder.png"}
                alt="item.image"
              />
            )}
          </div>
          <div className="p-1 sm:p-2 md:p-2 lg:p-2 ">
            <h5 className="mb-2 text-sm md:text-base lg:text-xl text-center font-bold text-black truncate">
              {item.name}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ProductCard: FC<any> = ({ item }) => {
  return (
    <div
      key={item.id}
      className="round bg-white border border-gray-200 shadow shadow-slate-300 rounded-lg cursor-pointer flex flex-col p-3 md:p-6 transition-all duration-300 transform  hover:-translate-y-2 hover:opacity-360"
    >
      <div className="h-28 sm:h-32 md:h-32 w-full flex items-center justify-center cursor-pointer ">
        {item.image ? (
          <img
            src={`${MEDIA_URL}${item.image}`}
            alt="Smartphone"
            className=" rounded-t-lg object-contain h-[130px] md:h-[150px] w-full"
          />
        ) : (
          <img
            className="rounded-t-lg h-[100px] md:h-[130px] my-5"
            src={"/placeholder.png"}
            alt="item.image"
          />
        )}
      </div>
      <h2 className="text-left font-semibold text-sm md:text-xl mt-3 sm:mt-8  truncate">
        {item.name}
      </h2>
      {/* <h2 className="text-left font-light text-xs md:text-sm text-slate-400 ">
        Price: ${item.price}
      </h2> */}
    </div>
  );
};
