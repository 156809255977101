import React, { useState } from "react";

const TermsConditionModal = ({ isOpen, setIsOpen,isChecked, setIsChecked }: any) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div>
      {isOpen && (
        <div className="fixed z-50 inset-0 flex  items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 w-11/12   lg:w-6/12 rounded-lg shadow-lg">
            {/* Modal Header */}
            <div className="pb-5">
              <div className="flex justify-between items-center ">
                <h2 className="text-xl font-bold">
                  Device Trade-In Terms and Conditions
                </h2>
                <button
                  onClick={() => setIsOpen(false)}
                  className="text-gray-500"
                >
                  ✖
                </button>
              </div>

              <p className="mt-2 font-light text-sm text-gray-600">
                By trading in your device with CMP Solutions Ltd, trading as
                CellMyPhone, you agree to the following terms and conditions:
              </p>
            </div>

            {/* Modal Content with See More Feature */}
            <div
              className={`mt-2 text-gray-600 space-y-4 overflow-y-auto transition-all ${
                isExpanded ? "max-h-64 md:max-h-96" : "max-h-48 md:max-h-64"
              }`}
            >
              <h3 className="font-bold text-black">1. Ownership</h3>
              <p className="font-light text-sm text-gray-600">
                • You confirm you are the legal owner of the device, and it is
                not lost, stolen, or under any financial agreement. We may
                reject devices that do not meet this condition.
              </p>

              <h3 className="font-bold text-black">2. Condition Standards</h3>
              <p className="font-light text-sm text-gray-600">
                • <strong className=" font-bold">Working:</strong> Device is in good working order,
                with no cracks or chips on the screen, frame, or back glass. It
                must power on, be free of iCloud/MDM locks, and have battery
                health above 80% (or fewer than 400 charge cycles for MacBooks).
              </p>
              <p className="font-light text-sm text-gray-600">
                • <strong className=" font-bold">Damaged:</strong> Device is functional but has a
                damaged screen or back cover; cracks and chips are acceptable on
                the screen or back. It must be free of iCloud/MDM locks, and the
                battery may be below 80% (or more than 400 cycles for MacBooks).
              </p>
              <p className="font-light text-sm text-gray-600">
                • <strong className=" font-bold">Broken:</strong> Device is non-functional, may not
                power on, may have suffered water damage, or have a smashed
                screen/back cover. It may also be iCloud or MDM locked.
              </p>
              <p className="font-light text-sm text-gray-600">
                Our quoted trade-in value is based on this condition assessment
                and may be adjusted upon inspection.
              </p>

              <h3 className="font-bold text-black">3. Data Responsibility</h3>
              <p className="font-light text-sm text-gray-600">
                • You are responsible for backing up and removing all personal
                data before trade-in. We will ensure that each device is
                securely wiped before resale. However, CMP Solutions Ltd is not
                liable for any residual data that may remain on the device.
              </p>

              <h3 className="font-bold text-black">4. Secure Transit</h3>
              <p className="font-light text-sm text-gray-600">
                • You are responsible for securely packaging the device for
                transit. CMP Solutions Ltd is not liable for any loss or damage
                that occurs before the device reaches our warehouse.
              </p>

              <h3 className="font-bold text-black">5. Government-Issued ID</h3>
              <p className="font-light text-sm text-gray-600">
                • A clear copy of your government-issued ID is required for the
                trade-in process. This will be held on file for up to 5 years
                and may be provided to any government agency, including the
                police, upon request.
              </p>

              <h3 className="font-bold text-black">6. Payment</h3>
              <p className="font-light text-sm text-gray-600">
                • Accepted trade-ins are paid via bank transfer or store credit
                within 3 business days. Store credit is non-transferable and has
                an expiration date.
              </p>

              <h3 className="font-bold text-black">7. Final Sale</h3>
              <p className="font-light text-sm text-gray-600">
                • Once accepted, the device ownership is transferred to us, and
                it cannot be returned.
              </p>

              <h3 className="font-bold text-black">8. Rejections and Returns</h3>
              <p className="font-light text-sm text-gray-600">
                • We reserve the right to reject any device if it doesn’t meet
                our standards. Unclaimed rejected devices may be disposed of
                after 30 days.
              </p>

              <h3 className="font-bold text-black">9. Liability</h3>
              <p className="font-light text-sm text-gray-600">
                • CMP Solutions Ltd is not liable for any damages, including
                data loss, related to the trade-in process.
              </p>
            </div>

            {/* See More Button */}
            <div className="text-right mt-2">
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                className="text-blue-600 underline"
              >
                {isExpanded ? "See Less" : "See More"}
              </button>
            </div>

            {/* Checkbox */}
            <div className="mt-4 flex items-center">
              <input
                type="checkbox"
                id="agree"
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
                className="mr-2 h-5 w-5"
              />
              <label htmlFor="agree" className="text-gray-700">
                I agree to the terms and conditions
              </label>
            </div>

            {/* Modal Footer */}
            <div className="mt-4 flex justify-end space-x-2">
              <button
                className="px-4 py-2 bg-gray-300 rounded-md"
                onClick={() => {setIsOpen(false)
                    setIsChecked(false)
                }}
              >
                Decline
              </button>
              <button
                className={`px-4 py-2 text-white rounded-md ${
                  isChecked ? "bg-blue-600" : "bg-gray-400 cursor-not-allowed"
                }`}
                disabled={!isChecked}
                onClick={()=>setIsOpen(false)}
              >
                I Accept
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TermsConditionModal;
