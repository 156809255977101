const Hero = () => {
  return (
    <div className="flex justify-between flex-col items-center mb-5">
      <div className="w-full md:w-5/6 flex justify-between flex-col items-center">
        <h1 className="text-center font-bold text-2xl md:text-3xl lg:text-4xl">
          Looking to sell your device?
        </h1>
        <h1 className=" text-justify sm:text-center font-light text-lg leading-tight mt-4">
          We are proud to be licensed second-hand dealers, offering a reliable
          and secure tech buyback service. Our commitment to quality and trust
          ensures that every device we buy and resell meets rigorous standards,
          providing peace of mind for both sellers and buyers in New Zealand and
          Australia.
        </h1>
        <h1 className=" text-justify sm:text-center font-light text-lg leading-tight mt-4">
          Whether you're an end-user with one device or a business with
          hundreds, our hassle-free buyback solution is for you! Don't
          compromise on value; we 100% guarantee a fair market price.{" "}
        </h1>
      </div>
    </div>
  );
};

export default Hero;
