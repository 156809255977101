export function Send() {
  return (
    <svg
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
    >
      <circle
        opacity="0.5"
        cx="25"
        cy="25"
        r="23"
        fill="url(#icon-payments-cat_svg__paint0_linear_1141_21101)"
      ></circle>
      <mask id="icon-payments-cat_svg__a" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.42 15.93c.382-1.145-.706-2.234-1.851-1.852l-18.568 6.189c-1.186.395-1.362 2-.29 2.644l5.12 3.072a1.464 1.464 0 001.733-.167l5.394-4.854a1.464 1.464 0 011.958 2.177l-5.154 4.638a1.464 1.464 0 00-.276 1.841l3.101 5.17c.644 1.072 2.25.896 2.645-.29L34.42 15.93z"
        ></path>
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.42 15.93c.382-1.145-.706-2.234-1.851-1.852l-18.568 6.189c-1.186.395-1.362 2-.29 2.644l5.12 3.072a1.464 1.464 0 001.733-.167l5.394-4.854a1.464 1.464 0 011.958 2.177l-5.154 4.638a1.464 1.464 0 00-.276 1.841l3.101 5.17c.644 1.072 2.25.896 2.645-.29L34.42 15.93z"
        fill="#fff"
      ></path>
      <path
        d="M25.958 20.962l-1.47-1.632 1.47 1.632zm2.067.109l-1.632 1.469 1.632-1.469zm-.109 2.068l-1.469-1.633 1.47 1.633zm-5.154 4.638l-1.469-1.632 1.469 1.632zm-.276 1.841l-1.883 1.13 1.883-1.13zM34.42 15.93l-2.084-.695 2.084.695zm-19.725 6.42l18.568-6.189-1.39-4.167-18.567 6.19 1.389 4.166zm5.265 1.75l-5.12-3.072-2.26 3.766 5.12 3.072 2.26-3.766zm2.072 3.348l5.394-4.854-2.938-3.264-5.394 4.854 2.938 3.264zm5.394-4.854a.732.732 0 01-1.034-.054l3.265-2.938a3.66 3.66 0 00-5.17-.272l2.939 3.265zm-1.034-.054a.732.732 0 01.054-1.034l2.938 3.265a3.66 3.66 0 00.273-5.169l-3.265 2.938zm.054-1.034l-5.154 4.639 2.938 3.264 5.154-4.638-2.938-3.265zm1.023 12.152l-3.101-5.17-3.766 2.26 3.101 5.17 3.766-2.26zm4.867-18.423l-6.189 18.568 4.167 1.389 6.19-18.568-4.168-1.389zm-8.633 20.682c1.61 2.682 5.622 2.241 6.611-.725l-4.167-1.39a.732.732 0 011.322-.144l-3.766 2.26zm-6.003-8.05a3.66 3.66 0 004.332-.419l-2.938-3.264a.732.732 0 01.866-.084l-2.26 3.766zm3.592-1.722a3.66 3.66 0 00-.69 4.603l3.766-2.26c.18.301.122.687-.138.921l-2.938-3.264zm11.97-9.984a.732.732 0 01-.925-.926l4.166 1.389c.954-2.861-1.768-5.583-4.63-4.63l1.39 4.167zm-19.956 2.022c-2.967.99-3.407 5.003-.726 6.611l2.26-3.766a.732.732 0 01-.145 1.322l-1.39-4.167z"
        fill="#fff"
        mask="url(#icon-payments-cat_svg__a)"
      ></path>
      <defs>
        <linearGradient
          id="icon-payments-cat_svg__paint0_linear_1141_21101"
          x1="25"
          y1="2"
          x2="25"
          y2="48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#fff" stop-opacity="0.71"></stop>
          <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export function ChevronUp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      viewBox="0 0 512 298.04"
    >
      <path
        fillRule="nonzero"
        d="M70.94 285.81c-16.17 16.24-42.46 16.32-58.71.15-16.24-16.16-16.32-42.46-.15-58.7L226.57 12.23c16.16-16.24 42.46-16.32 58.7-.15l214.65 215.18c16.17 16.24 16.09 42.54-.15 58.7-16.25 16.17-42.54 16.09-58.71-.15L256 100.29 70.94 285.81z"
      />
    </svg>
  );
}

export function ChevronDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      viewBox="0 0 512 298.04"
    >
      <path
        fillRule="nonzero"
        d="M12.08 70.78c-16.17-16.24-16.09-42.54.15-58.7 16.25-16.17 42.54-16.09 58.71.15L256 197.76 441.06 12.23c16.17-16.24 42.46-16.32 58.71-.15 16.24 16.16 16.32 42.46.15 58.7L285.27 285.96c-16.24 16.17-42.54 16.09-58.7-.15L12.08 70.78z"
      />
    </svg>
  );
}

export function Email() {
  return (
    <svg
      className="group-hover:ease-in-out transition duration-700 cursor-pointer h-24 w-24 object-center object-cover  group-hover:stroke-blue-400 fill-blue-400 group-hover:-translate-y-12 group-hover:-skew-y-12 group-hover:skew-x-12"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
    >
      <g data-name="open box">
        <path d="M29.86 11.49 27.1 6.88A1.85 1.85 0 0 0 25.54 6H6.46a1.85 1.85 0 0 0-1.56.88l-2.76 4.61A1 1 0 0 0 3 13h2v11.76A2.25 2.25 0 0 0 7.25 27h17.5A2.25 2.25 0 0 0 27 24.76V13h2a1 1 0 0 0 .86-1.51zM6.57 8h7l-1.2 3h-7.6zM26 11a1 1 0 0 0-1 1v12.76a.25.25 0 0 1-.25.24H7.25a.25.25 0 0 1-.25-.24V13h6a1 1 0 0 0 .93-.63L15 9.69V22a1 1 0 0 0 2 0V9.69l1.07 2.68A1 1 0 0 0 19 13h3a1 1 0 0 0 0-2h-2.32l-1.2-3h7l1.8 3z" />
        <path d="M10 22H9a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2z" />
      </g>
    </svg>
  );
}

export function Mail() {
  return (
    <svg
      className="w-4 h-4 text-gray-500 dark:text-gray-400"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 20 16"
    >
      <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
      <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
    </svg>
  );
}

export function BackArrow() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="15 18 9 12 15 6"></polyline>
    </svg>
  );
}

export function CircleTick() {
  return (
    <svg
      className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
    </svg>
  );
}

export function Search() {
  return (
    <svg
      className="w-6 h-6"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
      />
    </svg>
  );
}

export const Plus = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="13"
      height="13"
      viewBox="0,0,256,256"
    >
      <g
        fill="#9f9f9f"
        fill-rule="evenodd"
        stroke="none"
        stroke-width="1"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
      >
        <g transform="scale(10.66667,10.66667)">
          <path d="M11,2v9h-9v2h9v9h2v-9h9v-2h-9v-9z"></path>
        </g>
      </g>
    </svg>
  );
};

export const IDImage = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      shape-rendering="geometricPrecision"
      text-rendering="geometricPrecision"
      image-rendering="optimizeQuality"
      fill-rule="evenodd"
      clip-rule="evenodd"
      viewBox="0 0 512 328.373"
    >
      <path
        fill="#BCD9E4"
        d="M37.397 0h437.206C495.172 0 512 16.844 512 37.397v253.579c0 20.553-16.844 37.397-37.397 37.397H37.397C16.844 328.373 0 311.545 0 290.976V37.397C0 16.827 16.827 0 37.397 0z"
      />
      <path
        fill="#87B1BE"
        d="M37.397 0h437.206C495.172 0 512 16.851 512 37.397v35.915H0V37.397C0 16.827 16.827 0 37.397 0z"
      />
      <path
        fill="#85A9B3"
        d="M262.722 222.148c-3.119 0-5.629-3.373-5.629-7.507 0-4.134 2.51-7.506 5.629-7.506h194.095c3.119 0 5.629 3.372 5.629 7.506s-2.51 7.507-5.629 7.507H262.722zm.003 45.418c-3.118 0-5.632-3.369-5.632-7.507 0-4.134 2.514-7.506 5.632-7.506h194.092c3.119 0 5.633 3.372 5.633 7.506 0 4.138-2.514 7.507-5.633 7.507H262.725zm-.003-136.068c-3.119 0-5.629-3.372-5.629-7.507 0-4.137 2.51-7.506 5.629-7.506h139.05c3.119 0 5.633 3.369 5.633 7.506 0 4.135-2.514 7.507-5.633 7.507h-139.05zm0 45.325c-3.119 0-5.629-3.369-5.629-7.506 0-4.134 2.51-7.507 5.629-7.507h193.895c3.118 0 5.629 3.373 5.629 7.507 0 4.137-2.511 7.506-5.629 7.506H262.722z"
      />
      <path
        fill="#194794"
        fill-rule="nonzero"
        d="M159.457 203.665c5.761 12.822 19.363 15.086 30.833 17.303 16.777 3.241 37.565 19.084 37.565 37.039v7.317a2.246 2.246 0 01-2.244 2.241H51.798a2.246 2.246 0 01-2.244-2.241v-6.62c0-21.574 21.815-34.317 39.444-36.676 12.826-1.715 23.5-3.455 28.014-18.959 1.168 1.023 2.395 2.056 3.646 3.169 11.684 10.387 24.848 10.838 36.104-.017.925-.895 1.829-1.737 2.695-2.556z"
      />
      <path
        fill="#D2A75F"
        fill-rule="nonzero"
        d="M159.457 203.668c3.014 6.706 8.173 10.526 14.046 12.957-19.867 14.576-47.295 13.734-67.727 1.277 6.189-3.334 9.288-8.16 11.229-14.836 1.168 1.023 2.399 2.056 3.653 3.172 11.684 10.387 24.848 10.838 36.104-.017.925-.895 1.829-1.737 2.695-2.553z"
      />
      <path
        fill="#DBB26F"
        fill-rule="nonzero"
        d="M139.81 227.397c-11.881.053-23.831-3.277-34.034-9.495 6.166-3.32 9.344-8.223 11.232-14.836 1.172 1.023 2.396 2.056 3.65 3.172 6.136 5.455 12.687 8.169 19.152 7.946v13.213z"
      />
      <path
        fill="#E9BE79"
        d="M98.547 168.146c1.542-4.412 5.125-2.993 10.234-1.128l-.046-.225.046.027c3.607-37.862 27.794-16.735 46.181-35.829l.621-.492a38.667 38.667 0 013.132 1.746c7.838 5.267 12.59 14.863 11.17 33.618l.05-.04a94.616 94.616 0 01-.508 3.313c4.647-3.521 11.342-3.191 9.237 4.604l-2.878 8.148c-.69 1.954-1.148 2.66-3.614 2.528-1.088-.06-2.184-.479-3.276-1.201 1.009 12.025-4.828 15.949-12.135 23.001-11.253 10.861-24.421 10.412-36.1.02-6.844-6.085-12.92-9.781-13.223-22.292-1.772.544-3.449.643-4.914-.191-2.921-1.66-3.983-6.495-4.141-9.591-.067-1.243-.014-4.748.164-6.016z"
      />
      <path
        fill="#F2CD8C"
        d="M98.547 168.146c1.543-4.413 5.126-2.993 10.235-1.13l-.046-.22.046.025c2.663-27.955 16.542-23.751 31.027-27.321v74.683c-6.464.226-13.012-2.486-19.149-7.946-6.842-6.086-12.919-9.78-13.22-22.292-1.774.543-3.452.642-4.916-.191-4.251-2.419-4.594-11.169-3.977-15.608z"
      />
      <path
        fill="#333231"
        d="M89.715 128.611c22.538-27.85 48.515-42.998 68.022-18.222 23.904 1.255 32.196 40.258 12.147 55.475 1.606-21.185-4.665-30.686-14.362-35.461-18.456 20.125-43.096-1.828-46.74 36.417l-8.845-4.606c-.879-10.97 1.691-30-10.222-33.603z"
      />
    </svg>
  );
};
